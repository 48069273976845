import React, { useState, useEffect } from "react";
import SEO from "../../components/Seo";
import Strings from "../../constants/Strings";
import { xhrPredictFromDateAndRequestHash } from "../../lib/HttpDataBuilder";
import { changeOrientation } from "../../lib/ExifOrientation";
import ModelId from "../../constants/ModelId";
import Bytes from "../../constants/Bytes";
import { ImageClassifications } from "../../types/ImageClassifications";
import { requestHashFromFileEvent } from "../../lib/CryptoHelper";
import { blobToFile } from "../../lib/BlobToFile";
import { formDataBuilder } from "../../lib/FormDataBuilder";
import ImageClassificationSerializer from "../../data/ImageClassificationSerializer";
import Colors from "../../constants/Colors";

import CallToAction from "../../components/CallToAction";
import Layout from "../../components/Layout";
import SpacingVertical from "../../components/SpacingVertical";
import LoadingSpinner from "../../components/models/LoadingSpinner";
import PredictionContainer from "../../components/models/PredictionContainer";
import RowPredictionResult from "../../components/models/RowPredictionResult";
import Slider from "../../components/models/Slider";
import FormName from "../../constants/FormName";

const THRESHOLD = 0.9;
const IMAGES = [
  require("../../images/models/clothing-sweatshirt.jpg"),
  require("../../images/models/clothing-tshirt.jpg"),
  require("../../images/models/clothing-jeans.jpg"),
  require("../../images/models/clothing-loafer.jpg"),
  require("../../images/models/clothing-running-shoe.jpg"),
  require("../../images/models/clothing-sandal.jpg"),
  require("../../images/models/clothing-suit.jpg"),
  require("../../images/models/clothing-bow-tie.jpg"),
  require("../../images/models/clothing-sunglasses.jpg"),
  require("../../images/models/clothing-necklace.jpg"),
  require("../../images/models/equipment-crane.jpg"),
  require("../../images/models/equipment-fire-truck.jpg"),
  require("../../images/models/equipment-forklift.jpg"),
  require("../../images/models/equipment-garbage-truck.jpg"),
  require("../../images/models/equipment-semi-truck.jpg"),
  require("../../images/models/equipment-tow-truck.jpg"),
  require("../../images/models/equipment-tractor.jpg"),
  require("../../images/models/equipment-truck.jpg"),
  require("../../images/models/equipment-jeep.jpg"),
];

const ImageClassification = () => {
  const [prediction, setPrediction] = useState<ImageClassifications | null>(
    null
  );
  const [imageIndex, setImageIndex] = useState(0);
  const [image, setImage] = useState<ArrayBuffer | string | undefined | null>(
    undefined
  );
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [threshold, setThreshold] = useState(THRESHOLD);

  useEffect(() => {
    onGalleryItemPress(0);
  }, []);

  const showError = (error: string) => {
    setError(error);
    setPrediction(null);
  };

  const analyze = (file: File) => {
    changeOrientation(file)
      .then((newFile: Blob) => readAndPostToServer(newFile))
      .catch(() => setError(Strings.Models.error));
  };

  const readAndPostToServer = (file: File | Blob) => {
    var reader = new FileReader();
    reader.onload = function(e) {
      const { date, requestHash } = requestHashFromFileEvent(
        e,
        ModelId.imageClassification
      );

      const xhr = xhrPredictFromDateAndRequestHash(date, requestHash);
      xhr.onload = (event: any) => {
        if (event.target.status === 200) {
          const response = JSON.parse(event.target.responseText);
          const prediction = ImageClassificationSerializer(response);
          setError(null);
          setPrediction(prediction);
        } else {
          showError(Strings.Models.error);
        }
        setLoading(false);
      };

      xhr.onloadend = (event: any) => {
        setLoading(false);
      };

      xhr.onerror = (event: any) => {
        showError(Strings.Models.error);
      };

      xhr.ontimeout = (event: any) => {
        showError(Strings.Models.error);
      };

      const fileData = formDataBuilder(file, ModelId.imageClassification);
      setLoading(true);
      xhr.send(fileData);
    };
    reader.readAsBinaryString(file);
  };

  const onGalleryItemPress = (index: number) => {
    setPrediction(null);
    setError(null);
    setImage(null);
    setImageIndex(index);

    fetch(IMAGES[index])
      .then((res) => res.blob())
      .then((blob) => {
        const file = blobToFile(blob);
        analyze(file);
      });
  };

  const onCameraClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      const blob = event.target.files[0];
      const file = blobToFile(blob);
      let reader = new FileReader();
      reader.onload = (e) => {
        setPrediction(null);
        setError(null);
        setImage(e.target?.result);
      };
      reader.readAsDataURL(file);

      if (file.size > Bytes.fiveMB) {
        setError(Strings.Models.errorSize);
      } else {
        analyze(file);
      }
    }
  };

  const onSliderChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setThreshold(parseFloat(event.target.value));
  };

  const tableRows = () => {
    const rows = () => {
      if (loading) return <LoadingSpinner />;
      if (error) return <p style={styles.error}>{error}</p>;
      if (prediction === null) return null;

      const { names, scores } = prediction;
      return names.map((name, index) => {
        const confidence = scores[index];
        if (confidence < threshold) return null;
        return (
          <div key={`${name}-${index}`}>
            <RowPredictionResult name={name} confidence={confidence} />
            <SpacingVertical rems={2} />
          </div>
        );
      });
    };
    return (
      <div>
        <p style={styles.text}>{`${Strings.Threshold.title} ${threshold}`}</p>
        <Slider value={threshold} onChange={onSliderChange} />
        <SpacingVertical rems={1} />
        {rows()}
      </div>
    );
  };

  return (
    <Layout override="marginStandard">
      <SpacingVertical rems={4} />
      <SEO title={Strings.Models.ImageClassification.title} />
      <PredictionContainer
        title={Strings.Models.ImageClassification.title}
        description={Strings.Models.ImageClassification.description}
        image={image}
        images={IMAGES}
        imageIndex={imageIndex}
        threshold={threshold}
        onCameraClick={onCameraClick}
        onGalleryItemPress={onGalleryItemPress}
        tableRows={tableRows()}
      />
      <SpacingVertical rems={4} />
      <CallToAction
        backgroundColor={Colors.grayLight}
        inputColor={Colors.white}
        labelColor={Colors.blueDark}
        title={Strings.Models.ImageClassification.Form.title}
        formName={FormName.imageClassifier}
      />
      <SpacingVertical rems={4} />
    </Layout>
  );
};

const styles = {
  error: { color: Colors.primary },
  text: { margin: 0 },
};

export default ImageClassification;
