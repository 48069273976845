import {
  ImageClassificationsRaw,
  ImageClassifications,
} from "../types/ImageClassifications";

const ImageClassificationSerializer = (
  json: ImageClassificationsRaw
): ImageClassifications | null => {
  if (!json?.outputs) return null;
  const { class_names: names, probabilities } = json.outputs;
  const scores = probabilities[0];

  var list: { name: string; score: number }[] = [];
  names.map((name, index) => {
    list.push({ name: names[index], score: scores[index] });
  });

  for (var j = 0; j < names.length; j++) {
    //2) sort:
    list.sort(function(a, b) {
      return a.score > b.score ? -1 : a.score == b.score ? 0 : 1;
    });
  }

  //3) separate them back out:
  for (var k = 0; k < list.length; k++) {
    names[k] = list[k].name;
    scores[k] = list[k].score;
  }

  return {
    names,
    scores,
  };
};

export default ImageClassificationSerializer;
